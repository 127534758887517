import { createRouter, createWebHistory } from 'vue-router'
// Admin Layout
import AdminLayout from '@/layout/AdminLayout.vue'

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: AdminLayout,
    children: [
      {
        path: "",
        name: "DashboardPage",
        component: () => import("../views/Dashboard.vue"),
        meta: { authRequired: false, title: 'Home | Jewellery' },
      },
      {
        path: "/jewellery",
        name: "JewelleryPage",
        component: () => import("../views/Jewellery.vue"),
        meta: { authRequired: false, title: 'Jewellery | Jewellery' },
      },
      {
        path: "/jewellery-parts",
        name: "JewelleryPartsPage",
        component: () => import("../views/PartsOfJewellery.vue"),
        meta: { authRequired: false, title: 'Jewellery Parts | Jewellery' },
      },
      {
        path: "/playground",
        name: "PlaygroundPage",
        component: () => import("../views/Playground.vue"),
        meta: { authRequired: false, title: 'Playground | Jewellery' },
      },
    ]
  },

  // Login page
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
    meta: { authRequired: false, title: 'Login | Jewellery' },
  },
    // Register page
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "about" */ '../views/Register.vue'),
    meta: { authRequired: false, title: 'Register | Jewellery' },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  console.log("to", to);
  console.log("from", from);
  console.log("next", next());
  document.title = to.meta.title || 'Jewellery';

  window.scrollTo(0, 0);

  // if (!to.meta.middleware) {
  //     return next()
  // }
  // const middleware = to.meta.middleware
  // console.log('router * middleware', middleware);
  // const context = {
  //     to,
  //     from,
  //     next,
  //     store
  // }

  //  return middleware[0]({
  //     ...context,
  //     next: middlewarePipeline(context, middleware, 1)
  // })
});


export default router
