<template>
      <!-- ============================ Sidebar Start ============================ -->

      <aside class="sidebar">
        <!-- sidebar close btn -->
        <button
          type="button"
          class="sidebar-close-btn text-gray-500 hover-text-white hover-bg-main-600 text-md w-24 h-24 border border-gray-100 hover-border-main-600 d-xl-none d-flex flex-center rounded-circle position-absolute"
        >
          <i class="ph ph-x"></i>
        </button>
        <!-- sidebar close btn -->
  
        <router-link
          to="/"
          class="sidebar__logo text-center p-10 position-sticky inset-block-start-0 bg-white w-100 z-1"
        >
          <img src="images/logo/jewLogo.png" style="width: 160px;" alt="Logo" />
        </router-link>
  
        <div class="sidebar-menu-wrapper overflow-y-auto scroll-sm">
          <div class="p-20 pt-10">
            <ul class="sidebar-menu">
              <li class="sidebar-menu__item" :class="{ 'activePage': $route.path === '/' }">
                <router-link to="/" class="sidebar-menu__link">
                  <!-- <span class="icon"><i class="ph ph-clipboard-text"></i></span> -->
                  <span class="icon"><i class="ph ph-squares-four"></i></span>
                  <span class="text">Dashboard</span>
                </router-link>
              </li>
  
              <li class="sidebar-menu__item"  :class="{ 'activePage': $route.path === '/jewellery' }">
                <router-link to="/jewellery" class="sidebar-menu__link">
                  <span class="icon"><i class="ph ph-clipboard-text"></i></span>
                  <span class="text">Jewelry</span>
                </router-link>
              </li>
  
              <li class="sidebar-menu__item"  :class="{ 'activePage': $route.path === '/jewellery-parts' }">
                <router-link to="/jewellery-parts" class="sidebar-menu__link">
                  <span class="icon"><i class="ph ph-bookmarks"></i></span>
                  <span class="text">Jewelry's Parts</span>
                </router-link>
              </li>
              <li class="sidebar-menu__item"  :class="{ 'activePage': $route.path === '/playground' }">
                <router-link to="/playground" class="sidebar-menu__link">
                  <!-- <span class="icon"><i class="ph ph-bookmarks"></i></span> -->
                  <span class="icon"><i class="ph ph-books"></i></span>
                  <span class="text">Playground</span>
                </router-link>
              </li>
  
              <!-- <li class="sidebar-menu__item">
                      <span class="text-gray-300 text-sm px-20 pt-20 fw-semibold border-top border-gray-100 d-block text-uppercase">Settings</span>
                  </li> -->
  
              <!-- <li class="sidebar-menu__item has-dropdown">
                      <a href="javascript:void(0)" class="sidebar-menu__link">
                          <span class="icon"><i class="ph ph-shield-check"></i></span>
                          <span class="text">Authetication</span>
                      </a>
                      Submenu start
                      <ul class="sidebar-submenu">
                          <li class="sidebar-submenu__item">
                              <a href="sign-in.html" class="sidebar-submenu__link">Sign In</a>
                          </li>
                          <li class="sidebar-submenu__item">
                              <a href="sign-up.html" class="sidebar-submenu__link">Sign Up</a>
                          </li>
                          
                      </ul>
                      Submenu End
                  </li> -->
            </ul>
          </div>
          <!-- <div class="p-20 pt-80">
              <div class="bg-main-50 p-20 pt-0 rounded-16 text-center mt-74">
                  <span class="border border-5 bg-white mx-auto border-primary-50 w-114 h-114 rounded-circle flex-center text-success-600 text-2xl translate-n74">
                      <img src="images/icons/certificate.png" alt="" class="centerised-img">
                  </span>
                  <div class="mt-n74">
                      <h5 class="mb-4 mt-22">Get Pro Certificate</h5>
                      <p class="">Explore 400+ courses with lifetime members</p>
                      <a href="pricing-plan.html" class="btn btn-main mt-16 rounded-pill">Get Access</a>
                  </div>
              </div>
          </div> -->
        </div>
      </aside>
      <!-- ============================ Sidebar End  ============================ -->
</template>

<script>
export default {
  name: "SidebarPage",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.activePage a{
  color: white !important;
}
</style>
