<!-- AdminLayout.vue -->
<template>
    <div>
        <div>
            <Sidebar />
            <div class="dashboard-main-wrapper">
                <Header />
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>
    
<script>
import Header from "../components/adminLayout/Header.vue";
import Sidebar from "../components/adminLayout/Sidebar.vue";

export default {
name: "AdminLayout",
components: { Header, Sidebar },
mounted(){
}
};
</script>
    